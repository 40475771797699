import DashboardPage from './DashboardPage';
import React, {useEffect, useState} from 'react';
import {Link, navigate} from "gatsby";

interface Props {
    path?: string;
    default?: boolean;
}


export const NotFoundComponent = ({}: Props) => {

    const secsToWaitBeforeRedirect = 5;

    const [secsBeforeRedirect, setSecsBeforeRedirect] = useState(secsToWaitBeforeRedirect);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSecsBeforeRedirect(secsBeforeRedirect - 1);
        }, 1000);

        return (): void => clearInterval(intervalId);

    }, [secsBeforeRedirect]);


    if (secsBeforeRedirect == 0) {
        navigate('/');
    }

    return (
        <DashboardPage seoTitle={'404: Not Found'} sideMenuItems={[]}>
            <h1>Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist.</p>
            <Link to={'/'}>Click to return to the main page.</Link> Redirecting in {secsBeforeRedirect} seconds.
        </DashboardPage>
    );
};

