import React, {useEffect, useState} from 'react';
import '../spinner.css';
import DashboardPage from '../DashboardPage';
import './download.scss';
import {PortalSiteMetadata} from '../../pages/app';
import {MountedReference} from '../control-plane/FastConstants';
import {getIdToken} from '../../services/auth';
import {FormGroup, Table} from 'reactstrap';


export interface GuiArtifact {
    version: string;
    dtg: string;
    deployment: string;
}

export interface DownloadArtifact {
    version: string;
    key: string;
}

export const numberOfVersionsToDisplay = 20;

const fetchInstallerVersions = async (client: string, isQa: boolean): Promise<GuiArtifact[]> => {

    return fetch('/api/portal/download/installer?qa=' + isQa, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getIdToken(),
        },
    })
        .then(response => {
            return response;
        })
        .then(response => response.json())
        .then((resultData: GuiArtifact[]) => {
            if (resultData.length > 0) {
                if (!isQa) {
                    // truncate list of non-qa versions
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                }
                return resultData;
            } else {
                return [];
            }
        });
};

const doPerformDownloadInstaller = async (params: string) => {
    if (params) {
        const token = await getIdToken();
        await fetch('/api/portal/download/installer/' + params + '&redirect=false&isMsi=true', {
            headers: {
                'Authorization': token,
            },
        })
            .then(response => {
                if (response.status != 200) {
                    throw new Error('Unexpected return code in downloading installer: ' + response.status);
                }
                return response.text();
            })
            .then(text => {
                window.open(text);
            })
            .catch(err => {
                console.error('error: ' + err)
            });
    }
};

const Downloads = (siteMetadata: PortalSiteMetadata) => {

    const mounted: MountedReference = {mounted: true};

    const [installerVersions, setInstallerVersions] = useState<GuiArtifact[]>([]);

    const [qaInstallerVersions, setQaInstallerVersions] = useState<GuiArtifact[]>([]);

    const [isDownloadingQaInstaller, setIsDownloadingQaInstaller] = useState(true);
    const [isDownloadingInstaller, setIsDownloadingInstaller] = useState(false);

    const downloadInstaller = async (event: any) => {
        const params = event.target.getAttribute('data-value');
        doPerformDownloadInstaller(params);
    };

    useEffect(() => {

        fetchInstallerVersions(siteMetadata.client, true)
            .then((artifacts: GuiArtifact[]) => {
                if (mounted.mounted) {
                    setQaInstallerVersions(artifacts);
                    setIsDownloadingQaInstaller(false);
                }
            })
            .catch((err) => {
                console.log("Error downloading QA installer artifact details: " + err);
            })
            .finally(() => {
                if (mounted.mounted) {
                    setIsDownloadingQaInstaller(false);
                }
            });

        if (siteMetadata.clientsToShowNonQaBuildsTo.includes(siteMetadata.client)) {
            setIsDownloadingInstaller(true);

            fetchInstallerVersions(siteMetadata.client, false)
                .then((artifacts: GuiArtifact[]) => {
                    if (mounted.mounted) {
                        setInstallerVersions(artifacts);
                        setIsDownloadingInstaller(false);
                    }
                })
                .catch((err) => {
                    console.log("Error downloading installer artifact details: " + err);
                })
                .finally(() => {
                    if (mounted.mounted) {
                        setIsDownloadingInstaller(false);
                    }
                });
        }

        return () => {
            mounted.mounted = false;
        };

    }, []);

    return (

        <DashboardPage seoTitle={'Downloads'} sideMenuItems={[]}>
            <h1>Downloads</h1>
                <FormGroup tag="fieldset">
                    <legend className={'text-muted'}>Installer</legend>

                    {isDownloadingQaInstaller ?
                        <div><p>Checking available downloads, please wait...</p>
                            <div className="lds-dual-ring"></div>
                        </div> :
                        <div>
                            {qaInstallerVersions.length == 0 ?
                                <p>No downloads of the installer found.</p> :
                                <div>
                                    <p>The most recent versions of the MAIA installer are
                                        available for download here:</p>
                                        <Table size='sm' hover>
                                            <thead>
                                                <tr>
                                                    <th>Version</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {qaInstallerVersions.map((binary) =>
                                                <tr key={binary.version}>
                                                    <td>
                                                        <a onClick={downloadInstaller} href={'#'}
                                                           data-value={binary.version + ('?isAll=false')}>{binary.version}</a>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </Table>
                                </div>
                            }
                        </div>
                    }
                    {siteMetadata.clientsToShowNonQaBuildsTo.includes(siteMetadata.client) ?
                        isDownloadingInstaller ?
                            <div><p>Checking available downloads, please wait...</p>
                                <div className="lds-dual-ring"></div>
                            </div> :
                            <div>
                                <h4 className={'text-muted'}>Recent compatible non-QA versions</h4>
                                {installerVersions.length == 0 ?
                                    <p>No non-QA downloads of the auto-updating application found.</p> :
                                    <div>
                                        <span> Last {installerVersions.length > numberOfVersionsToDisplay ? numberOfVersionsToDisplay : installerVersions.length} compatible installer version(s):
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Version</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                {installerVersions.map((binary) =>
                                                    <tr key={binary.version}>
                                                        <td>
                                                            <a onClick={downloadInstaller} href={'#'}
                                                               data-value={binary.version + ('?isAll=false')}>{binary.version}</a>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </span>
                                    </div>
                                }
                            </div>
                        : null}
                </FormGroup>
        </DashboardPage>
    );
};

export default Downloads;