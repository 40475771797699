import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Amplify from 'aws-amplify';

import Layout from '../components/Layout';
import config from '../services/aws-exports';
import { Router } from '@reach/router';
import PrivateRoute from '../components/PrivateRoute';
import { ControlPlane } from '../components/control-plane/ControlPlane';
import Downloads from '../components/downloads/Downloads';
// import UserDocs from '../components/docs/user';
// import DeveloperDocs from '../components/docs/developer';
import { NotFoundComponent } from '../components/NotFoundComponent';
import FederatedLoginCallback from '../components/auth/FederatedLoginCallback';
import { isPermittedForUserRoles } from '../services/auth';
// import InternalDeveloperDocs from '../components/docs/internal';

Amplify.configure(config);

export interface PortalSiteMetadata {
    client: string;
    clientsToShowNonQaBuildsTo: string[];
    clientsToShowUatBuildsTo: string[];
    clientsToShowDevBuildsTo: string[];
}

const App = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
          clientsToShowNonQaBuildsTo
          clientsToShowUatBuildsTo
          clientsToShowDevBuildsTo
        }
      }
    }
  `);

    return (
        <Layout>
            <Router>
                {isPermittedForUserRoles(data.site.siteMetadata.client, ['admin'], false) &&
                <PrivateRoute path={'/app/control/:action'} component={ControlPlane}/>
                }

                <FederatedLoginCallback path={'/app/federatedLogin'}/>
                {/*<PrivateRoute path={'/app/docs/user'} component={UserDocs}/>*/}
                {/*<PrivateRoute path={'/app/docs/developer'} component={DeveloperDocs}/>*/}
                {/*<PrivateRoute path={'/app/docs/internal'} component={InternalDeveloperDocs}/>*/}
                <PrivateRoute path={'/app/downloads'} component={Downloads} componentProps={data.site.siteMetadata}/>
                <NotFoundComponent default/>
            </Router>
        </Layout>
    );
};

export default App;
