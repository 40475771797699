import React from 'react';
import queryString from 'query-string';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import { navigate } from 'gatsby';
import { onAuthStateChange } from '../../services/auth';

interface CognitoJwtPayload extends JwtPayload {
    'email' : string | null | undefined;
    'cognito:username' : string | null | undefined;
}

interface Props {
    path: string;
}

const getAccessToken = (query: string): string => {
    const fallback = '';

    if (query) {
        const queriedToken = queryString.parse(query);
        const { access_token } = queriedToken;

        return access_token as string;
    }

    return fallback;
};

const getIdToken = (query: string): string => {
    const fallback = '';

    if (query) {
        const queriedToken = queryString.parse(query);
        const { id_token } = queriedToken;

        return id_token as string;
    }

    return fallback;
};

const FederatedLoginCallback = (props: Props) => {
    const accessToken = (location.hash && getAccessToken(location.hash)) || '';
    const idToken = (location.hash && getIdToken(location.hash)) || '';

    const decodedIdToken = jwtDecode<CognitoJwtPayload>(idToken);

    const userData = {
        UserAttributes: [
            {
                Name: 'sub',
                Value: decodedIdToken.sub,
            },
            {
                Name: 'email_verified',
                Value: "true",
            },
            {
                Name: 'email',
                Value: decodedIdToken.email,
            },
        ],
        Username: decodedIdToken['cognito:username'],
    };


    const federatedLoginLocalStorage : { [key: string] : string | number } = {
        idToken: idToken,
        accessToken: accessToken,
        clockDrift: 0,
        userData: JSON.stringify(userData),
        refreshToken: '',
    };

    const aud = decodedIdToken.aud;
    const sub = decodedIdToken.sub;

    Object.keys(federatedLoginLocalStorage).forEach((key) => {
        const cognitoKey = 'CognitoIdentityServiceProvider.' + aud + '.' + sub + '.' + key;
        window.localStorage[cognitoKey] = federatedLoginLocalStorage[key];
    });

    window.localStorage['amplify-authenticator-authState'] = 'signedIn';
    window.localStorage['CognitoIdentityServiceProvider.' + aud + '.LastAuthUser' ] = decodedIdToken.sub;

    onAuthStateChange('signedIn')
        .then((user) => {
            navigate('/app/downloads')
        });

    return (<div>
    Loading...
    </div>);
};

export default FederatedLoginCallback;