import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { getIdToken } from '../../../services/auth';
import { InfraMetadata } from '../../../../../infrastructure/src/maiadeploy/model/infraMetadata';
import { ConversionSpec } from '../../../../../infrastructure/src/maiadeploy/model/conversionSpec';


interface Props {
    environment: string;
    tenant: string;
}

const DataConversionEnvironmentPanel = ({ environment, tenant }: Props) => {

    const deploymentsEnv = 'deployments' + environment;

    const [isQueryingDeployments, setIsQueryingDeployments] = useState<boolean>(false);

    const [fileErrorMessage, setFileErrorMessage] = useState<string>('');

    const [availableDeployments, setAvailableDeployments] = useState<string[]>([]);

    const [selectedDeployment, setSelectedDeployment] = useState<string>('');

    const [jsonB64Diff, setJsonB64Diff] = useState<string>('');

    const [isConvertEnabled, setIsConvertEnabled] = useState<boolean>(false);


    const onSelectDeployment = (e: any) => {
        setSelectedDeployment(e.target.value);
    };

    const [showModalLoad, setShowModalConversion] = useState<boolean>(false);

    const toggleModalConversion = () => {
        setShowModalConversion(!showModalLoad);
    };


    const onConvert = () => {
        if (!selectedDeployment || !jsonB64Diff || !jsonB64Diff.length) {
            return;
        }
        toggleModalConversion();
    };

    const onDiffFileSelect = (event: any) => {
        const files: FileList = event.target.files;
        if (!files.length) {
            setFileErrorMessage('');
            setJsonB64Diff('');
            setIsConvertEnabled(false);
            return;
        }
        // max 1M upload
        const maxSize = 1000000;

        console.log(files[0].name);
        if (files[0].size > maxSize) {
            setFileErrorMessage('File is too big, max size: ' + maxSize);
            setJsonB64Diff('');
            setIsConvertEnabled(false);
            return;
        } else {
            setFileErrorMessage('');
            setIsConvertEnabled(true);
        }


        const reader = new FileReader();
        reader.onload = () => {
            let b64 = reader.result as string;
            b64 = b64.replaceAll(/^data:.*base64,/g, '');
            setJsonB64Diff(b64);
        };

        reader.onerror = () => {
            setJsonB64Diff('');
            setFileErrorMessage('Error reading file contents: ' + reader.error);
        };

        reader.readAsDataURL(files[0]);
    };

    const performConversion = async (env: string, deploymentId: string) => {
        console.log('performing conversion of data into ' + deploymentId + ' in env ' + env);
        const conversionSpec : ConversionSpec = {
          diffs: jsonB64Diff
        };
        fetch('/api/maiadeploy/maia/data/' + env + '/' + deploymentId + '/convert/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getIdToken(),
            },
            body: JSON.stringify(conversionSpec),

        })
            .catch((error) => {
                console.log('error: ' + error);
            })
            .finally(() => {
                setSelectedDeployment('');
                toggleModalConversion();
            });
    };

    useEffect(() => {
        let mount = true;
        setIsQueryingDeployments(true);
        // hard code in linux os as we are creating infra in AWS
        async function fetchDeployments() {
          await fetch('/api/maiadeploy/maia/infra/' + environment, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': await getIdToken(),
            },
          })
            .then(response => response.json())
            .then((json: InfraMetadata[]) => {
              if (mount) {
                setAvailableDeployments(json.map(sys => sys.name));
                setIsQueryingDeployments(false);
              }
            })
            .catch((error) => {
              if (mount) {
                console.log('error: ' + error);
                setIsQueryingDeployments(false);
              }
            });
          return () => {
            mount = false;
          };
        }
        fetchDeployments();

    }, []);

    return (
        <Col sm="12">
            <Form>
                <FormGroup tag="fieldset">
                    <legend className={'text-muted'}>{environment.toUpperCase()}</legend>
                    <Row form>
                        <Col sm={12} md={6}>

                            <FormGroup>
                                <Label for={deploymentsEnv}>Available {environment.toUpperCase()} deployments</Label>
                                <Input type="select" name={deploymentsEnv} id={deploymentsEnv} size={10}
                                       value={selectedDeployment}
                                       onChange={onSelectDeployment}>
                                    {
                                        isQueryingDeployments ? <option className="lds-dual-ring"></option> :

                                            Array.of(<option key="" value="" hidden>None</option>).concat(
                                                Array.from(availableDeployments.map(deployment =>
                                                    <option key={deployment}
                                                            value={deployment}>{deployment}</option>,
                                                )),
                                            )
                                    }

                                </Input>
                            </FormGroup>
                        </Col>

                        <Col sm={12} md={6}>
                            <Row>
                                {fileErrorMessage &&
                                <Alert color="danger">
                                    {fileErrorMessage}
                                </Alert>
                                }
                                <Col sm={{ size: 10, offset: 1 }} className={'diffFileRow'}>
                                    <Label for="diffFile">Select the file with JSON serialized diffs:</Label>
                                    <Input type="file" name="diffFile" id="diffFile" onChange={onDiffFileSelect}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: 6, offset: 3 }} md={{ size: 2, offset: 5 }}>
                                    <Button outline block disabled={!isConvertEnabled || selectedDeployment === '' || fileErrorMessage !== ''}
                                            onClick={onConvert} color={'info'}>Convert</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </FormGroup>
            </Form>

            <Modal isOpen={showModalLoad} toggle={toggleModalConversion}>
                <ModalHeader toggle={toggleModalConversion}>Convert data
                    within <strong>{selectedDeployment}</strong>?</ModalHeader>
                <ModalBody>
                    Do you wish to convert the data within the <strong>{selectedDeployment}</strong> FAST deployment
                    within
                    the <strong>{environment.toUpperCase()}</strong> environment? This will mutate the data.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={toggleModalConversion}>Cancel</Button>
                    <Button color={'danger'} onClick={() => performConversion(environment, selectedDeployment)}
                    >Convert</Button>{' '}
                </ModalFooter>
            </Modal>

        </Col>
    );
};

export default DataConversionEnvironmentPanel;