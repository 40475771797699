import React from 'react';
import { Row } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
import EnginesEnvironmentPanel from './EnginesEnvironmentPanel';

const EnginesTabPaneContents = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
        }
      }
    }
  `);


    return (
        <Row>
          <EnginesEnvironmentPanel environment={"dev"} tenant={data.site.siteMetadata.client}/>
          <EnginesEnvironmentPanel environment={"uat"} tenant={data.site.siteMetadata.client}/>
          <EnginesEnvironmentPanel environment={"prod"} tenant={data.site.siteMetadata.client}/>

        </Row>
    );
};

export default EnginesTabPaneContents;