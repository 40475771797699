import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React from 'react';
import { Col, CustomInput, FormGroup, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { ReleaseMetadata } from '../../../../../infrastructure/src/maiadeploy/model/releaseMetadata';

interface Props {
    selectedReleaseMetadata : ReleaseMetadata  | undefined;
    onReleaseTypeChange : (event: any) => void;
    onPrimaryDataMigrationChange : (event: any) => void;
    onSecondaryDataMigrationChange : (event: any) => void;
    allowNameEdit: boolean;
    onNameEditChange?: (event: any) => void;
    idPrefix: string;
}

export const ReleaseMetadataForm = ({selectedReleaseMetadata, onReleaseTypeChange, onPrimaryDataMigrationChange, onSecondaryDataMigrationChange, allowNameEdit, onNameEditChange, idPrefix} : Props) => {
    return (
      <div>
          <Row>
              <Col sm={12} md={6}>

                  <FormGroup>
                      <Label for="name">Name <FontAwesomeIcon
                          className={'fa-semitransparent'}
                          id={idPrefix + 'releaseNameLabel'}
                          icon={faInfoCircle}/>
                          <UncontrolledTooltip placement="right-end" target={idPrefix + "releaseNameLabel"}>
                              Short description of the release, will form part of the release:
                              <br/>
                              <p>{moment().utc().format('YYYYMMDDTHHmm')}-<strong>&lt;name&gt;</strong>
                              </p>
                          </UncontrolledTooltip></Label>
                      <Input type="text" name="name" id={idPrefix + "name"}
                             placeholder="Short name"
                             value={selectedReleaseMetadata ? selectedReleaseMetadata.name : ''}
                             readOnly={!allowNameEdit}
                             onChange={onNameEditChange}
                             invalid={selectedReleaseMetadata && !(selectedReleaseMetadata.name.trim().length > 0)}
                      />
                  </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                  <FormGroup>
                      <Label for="version">Version <FontAwesomeIcon
                          className={'fa-semitransparent'}
                          id={idPrefix + 'releaseVersionLabel'}
                          icon={faInfoCircle}/>
                          <UncontrolledTooltip placement="right-end" target={idPrefix + "releaseVersionLabel"}>
                              Version of the release, auto-incremented if a release is cloned.
                          </UncontrolledTooltip></Label>
                      <Input type="text" name="version" id={idPrefix + "version"}
                             placeholder="Version"
                             value={selectedReleaseMetadata ? selectedReleaseMetadata.version : ''}
                             readOnly
                      />
                  </FormGroup>
              </Col>
          </Row>
          <hr/>
          <Label>Release type:</Label>
          <div className={'release-type-container'}>
              <FormGroup check>
                  <Label check>
                      <Input type="radio" name="releaseType" value={'MAIA_FULL'}
                             checked={selectedReleaseMetadata?.releaseType == ReleaseMetadata.ReleaseTypeEnum.MaiaFull}
                             onChange={onReleaseTypeChange}/>
                      Full <FontAwesomeIcon
                      className={'fa-semitransparent'}
                      id={idPrefix + 'fullReleaseLabel'}
                      icon={faInfoCircle}/>
                      <UncontrolledTooltip placement="right-end" target={idPrefix + "fullReleaseLabel"}>
                          <p>A <strong>full</strong> release involves the stop of all engines,
                              the
                              release
                              of the new software then an incremental start of all engines:
                              address then
                              persistence then all other engines that have ever been deployed
                              to
                              that FAST
                              deployment, with optional primary or secondary data migration
                              steps.
                          </p>
                      </UncontrolledTooltip>
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label check>
                      <Input type="radio" name="releaseType" value={'MAIA_PARTIAL'}
                             checked={selectedReleaseMetadata?.releaseType == ReleaseMetadata.ReleaseTypeEnum.MaiaPartial}
                             onChange={onReleaseTypeChange}/>
                      Partial <FontAwesomeIcon
                      className={'fa-semitransparent'}
                      id={idPrefix + 'partialReleaseLabel'}
                      icon={faInfoCircle}/>
                      <UncontrolledTooltip placement="right-end"
                                           target={idPrefix + "partialReleaseLabel"}>
                          <p>A <strong>partial</strong> release involves stopping only the
                              engines
                              defined
                              in the release and their subsequent restart, followed by an
                              optional
                              secondary data migration.</p>
                      </UncontrolledTooltip>
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label check>
                      <Input type="radio" name="releaseType" value={'OTHER'}
                             checked={selectedReleaseMetadata?.releaseType == ReleaseMetadata.ReleaseTypeEnum.Other}
                             onChange={onReleaseTypeChange}/>
                      Other <FontAwesomeIcon
                      className={'fa-semitransparent'}
                      id={idPrefix + 'otherReleaseLabel'}
                      icon={faInfoCircle}/>
                      <UncontrolledTooltip placement="right-end" target={idPrefix + "otherReleaseLabel"}>
                          <p>An <strong>other</strong> release involves stopping only the
                              applications
                              defined in the release and their subsequent restart. The release
                              process is
                              different to that used for a FAST engine.</p>
                      </UncontrolledTooltip>
                  </Label>
              </FormGroup>
          </div>
          <hr/>
          <Label>Data migration:</Label>
          <FormGroup>
              <CustomInput type="switch" id={idPrefix + "performDataMigration"} name="performDataMigration"
                           label="Perform data migration"
                           checked={selectedReleaseMetadata?.performDataMigration == true}
                           onChange={onPrimaryDataMigrationChange}> <FontAwesomeIcon
                  className={'fa-semitransparent'}
                  id={idPrefix + 'performDataMigrationLabel'}
                  icon={faInfoCircle}/>
                  <UncontrolledTooltip placement="right-end"
                                       target={idPrefix + "performDataMigrationLabel"}>
                      Data migration steps will be performed after the release of the new
                      software
                      and before any engines are restarted.
                  </UncontrolledTooltip></CustomInput>


          </FormGroup>
          <FormGroup>
              <CustomInput type="switch" id={idPrefix + "performSecondaryDataMigration"}
                           name="performSecondaryDataMigration"
                           label="Perform secondary data migration"
                           checked={selectedReleaseMetadata?.performSecondaryDataMigration == true}
                           onChange={onSecondaryDataMigrationChange}> <FontAwesomeIcon
                  className={'fa-semitransparent'}
                  id={idPrefix + 'performSecondaryDataMigrationLabel'}
                  icon={faInfoCircle}/>
                  <UncontrolledTooltip placement="right-end"
                                       target={idPrefix + "performSecondaryDataMigrationLabel"}>
                      Secondary data migration steps will be performed after the release of
                      the
                      new software and after the address and persistence engines have been
                      restarted.
                  </UncontrolledTooltip></CustomInput>
          </FormGroup>
      </div>
    );
};