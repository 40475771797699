import React from 'react';

const Status = () => {
    return (
      <div>
          <h1>Control plane - Status</h1>
          <p>Status dashboard here.</p>

      </div>
    );
};

export default Status;