/**
 * Maia Deploy API
 * REST API describing user interactions with Maia Deploy.
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReleaseMetadata {
  readonly id?: string;
  name: string;
  version: string;
  /**
     * AWS region where the target servers are located
     */
  readonly region?: string;
  /**
     * If this is a full release then all applications will be stopped using the poisoner before releasing, if partial then only the affected engines will be shutdown. If other then the poisoner will not be called.
     */
  releaseType: ReleaseMetadata.ReleaseTypeEnum;
  performDataMigration: boolean;
  performSecondaryDataMigration: boolean;
  performDatabaseBootstrap?: boolean;
  dataMigrationDiffPath?: string;
  releaseTime?: string;
  /** TODO
   * Add dataMigrationOverrides to support different diffs per env
   */
  readonly createdAt?: string;
  readonly status?: ReleaseMetadata.StatusEnum;
}
export namespace ReleaseMetadata {
  export type ReleaseTypeEnum = 'MAIA_FULL' | 'MAIA_PARTIAL' | 'OTHER';
  export const ReleaseTypeEnum = {
    MaiaFull: 'MAIA_FULL' as ReleaseTypeEnum,
    MaiaPartial: 'MAIA_PARTIAL' as ReleaseTypeEnum,
    Other: 'OTHER' as ReleaseTypeEnum,
  };
  export type StatusEnum = 'DRAFT' | 'DRAFT_FAILED_LOCKED' | 'LOCK_IN_PROGRESS' | 'LOCKED';
  export const StatusEnum = {
    Draft: 'DRAFT' as StatusEnum,
    DraftFailedLocked: 'DRAFT_FAILED_LOCKED' as StatusEnum,
    LockInProgress: 'LOCK_IN_PROGRESS' as StatusEnum,
    Locked: 'LOCKED' as StatusEnum,
  };
}


