import React from 'react';
import { navigate } from '@reach/router';
import { isLoggedIn } from '../services/auth';
import { Login } from './Login';

interface Props {
    path: string;
    component: any;
    componentProps?: any;
}

const PrivateRoute = ({ path, component: Component, componentProps, ...props }: Props)  => {
    if (!isLoggedIn()) {
        navigate(`/`);
        return (<Login />);
    }
    return (<Component {...props} {...componentProps} />);
};

export default PrivateRoute;