import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { GuiArtifact } from '../../downloads/Downloads';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
    versions: GuiArtifact[];
    qaVersions: GuiArtifact[];
    isDownloading: boolean;
    isDownloadingQa: boolean;
    title: string;
    env: string;
    markVersion: (version: string, deployment: string) => void;
    unmarkVersion: (version: string, deployment: string) => void;
    isTagging: boolean;
}

const capitalize = (s: any) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const QaTabPaneSection = ({ versions, qaVersions, title, env, isDownloading, isDownloadingQa, markVersion, unmarkVersion, isTagging }: Props) => {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
        }
      }
    }
  `);


    const [selectedQaVersion, setSelectedQaVersion] = useState<string>('');
    const [selectedQaDeployment, setSelectedQaDeployment] = useState<string>('');
    const [selectedVersion, setSelectedVersion] = useState<string>('');
    const [selectedDeployment, setSelectedDeployment] = useState<string>('');

    const [enableMarkButton, setEnableMarkButton] = useState<boolean>(false);
    const [enableUnmarkButton, setEnableUnmarkButton] = useState<boolean>(false);
    const [showMarkModal, setShowMarkModal] = useState<boolean>(false);
    const [showUnmarkModal, setShowUnmarkModal] = useState<boolean>(false);

    const toggleMarkModal = () => {
        setShowMarkModal(!showMarkModal);
    };

    const toggleUnmarkModal = () => {
        setShowUnmarkModal(!showUnmarkModal);
    };


    const onSelectVersion = (event: any) => {
        if (event.target.value == '') {
            setEnableMarkButton(false);
        } else {
            const elements: string[] = event.target.value.split(':');
            setSelectedVersion(elements[1]);
            setSelectedDeployment(elements[0]);
            setEnableMarkButton(true);

            // disable unmark button, deselect QA selection
            setEnableUnmarkButton(false);
            setSelectedQaVersion('');
            setSelectedQaDeployment('');
        }
    };

    const onSelectQaVersion = (event: any) => {
        if (event.target.value == '') {
            setEnableUnmarkButton(false);
        } else {
            const elements: string[] = event.target.value.split(':');
            setSelectedQaVersion(elements[1]);
            setSelectedQaDeployment(elements[0]);
            setEnableUnmarkButton(true);

            // disable unmark button, deselect QA selection
            setEnableMarkButton(false);
            setSelectedVersion('');
            setSelectedDeployment('');
        }
    };


    return (
        <FormGroup tag="fieldset">
            <legend className={'text-muted'}>{title}</legend>
            <Row form>
                <Col sm={12} md={5}>

                    <FormGroup>
                        <Label for="deployments">Available versions</Label>
                        {isDownloading ? <div><br/><div className="lds-dual-ring"></div></div> :
                            <Input type="select" name="releases" id="releases" size={10}
                                   value={selectedDeployment + ':' + selectedVersion}
                                   onChange={onSelectVersion}
                            >
                                <option value="" hidden></option>
                                {
                                    versions.map(gui =>
                                        <option key={gui?.deployment + ':' + gui?.version}
                                                value={gui?.deployment + ':' + gui?.version}>({gui.deployment}) {gui.version}</option>,
                                    )
                                }
                            </Input>
                        }
                    </FormGroup>
                </Col>
                <Col sm={12} md={2} className={'qa-button-container'}>
                    <Button color="secondary" outline disabled={!enableMarkButton || isTagging}
                            onClick={toggleMarkModal}>Mark QA <FontAwesomeIcon
                        icon={faAngleDoubleRight}/></Button>
                    <Button color="secondary" outline disabled={!enableUnmarkButton || isTagging}
                            onClick={toggleUnmarkModal}><FontAwesomeIcon
                        icon={faAngleDoubleLeft}/> Unmark QA</Button>
                </Col>
                <Col sm={12} md={5}>

                    <FormGroup>
                        <Label for="deployments">QA versions</Label>
                        {isDownloading || isTagging ? <div><br/><div className="lds-dual-ring"></div></div> :
                            <Input type="select" name="releases" id="releases" size={10}
                                   value={selectedQaDeployment + ':' + selectedQaVersion}
                                   onChange={onSelectQaVersion}
                            >
                                <option value="" hidden></option>
                                {
                                    qaVersions.map(gui =>
                                        <option key={gui?.deployment + ':' + gui?.version}
                                                value={gui?.deployment + ':' + gui?.version}>({gui.deployment}) {gui.version}</option>,
                                    )
                                }
                            </Input>
                        }
                    </FormGroup>
                </Col>
            </Row>
            <Modal isOpen={showMarkModal} toggle={toggleMarkModal}>
                <ModalHeader toggle={toggleMarkModal}>Mark GUI as QA?</ModalHeader>
                <ModalBody>
                    You are about to mark the <strong>{selectedVersion}</strong> binary for
                    the <strong>{selectedDeployment}</strong> deployment as QA for
                    the <strong>{capitalize(data.site.siteMetadata.client)} {env.toUpperCase()}</strong> environment,
                    which will cause it to appear in the user download portal. Please only continue if you are sure you
                    are happy with the quality and performance of the GUI.
                </ModalBody>
                <ModalFooter>
                    <Button className={'control-plane-button'}
                            onClick={() => {
                                markVersion(selectedVersion, selectedDeployment);
                                toggleMarkModal();
                            }}
                    >Mark QA</Button>{' '}
                    <Button color="secondary" outline onClick={toggleMarkModal}>Cancel</Button>
                </ModalFooter>

            </Modal>

            <Modal isOpen={showUnmarkModal} toggle={toggleUnmarkModal}>
                <ModalHeader toggle={toggleUnmarkModal}>Remove QA tag from GUI?</ModalHeader>
                <ModalBody>
                    You are about to remove the QA tag from the <strong>{selectedQaVersion}</strong> binary for
                    the <strong>{selectedQaDeployment}</strong> deployment as QA for
                    the <strong>{capitalize(data.site.siteMetadata.client)} {env.toUpperCase()}</strong> environment,
                    which will cause it to no longer appear in the user download portal. Please only continue if you are
                    sure you do not wish users to be able to download this version.</ModalBody>
                <ModalFooter>
                    <Button className={'control-plane-button'}
                            onClick={() => {
                                unmarkVersion(selectedQaVersion, selectedQaDeployment);
                                toggleUnmarkModal();
                            }}
                    >Unmark QA</Button>{' '}
                    <Button color="secondary" outline onClick={toggleUnmarkModal}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </FormGroup>

    );
};

export default QaTabPaneSection;