import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ReleaseTabPaneContents from './ReleaseTabPaneContents';
import QaTabPaneContents from './QaTabPaneContents';
import DataConversionTabPaneContents from './DataConversionTabPaneContents';
import EnginesTabPaneContents from './engines/EnginesTabPaneContents';

const Release = () => {

    const [activeTab, setActiveTab] = useState('release');

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div>
            <h2>Releases</h2>
            <p>Release new versions of software to an existing FAST deployment or manage which GUI versions are QA. If you need to create a FAST deployment
                first, then this can be done on the <Link to={'/app/control/deploy'}>Deployments</Link> page.</p>
            <br/>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab === 'release' ? 'active' : ''}
                        onClick={() => {
                            toggle('release');
                        }}
                    >
                        Release
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === 'qa' ? 'active' : ''}
                        onClick={() => {
                            toggle('qa');
                        }}
                    >
                        QA
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === 'data-conversion' ? 'active' : ''}
                        onClick={() => {
                            toggle('data-conversion');
                        }}
                    >
                        Data Conversion
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === 'engines' ? 'active' : ''}
                        onClick={() => {
                            toggle('engines');
                        }}
                    >
                        Engines
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="release">
                    <ReleaseTabPaneContents/>
                </TabPane>
                <TabPane tabId="qa">
                    <QaTabPaneContents/>
                </TabPane>
                <TabPane tabId="data-conversion">
                    <DataConversionTabPaneContents/>
                </TabPane>
                <TabPane tabId="engines">
                    <EnginesTabPaneContents/>
                </TabPane>
            </TabContent>

        </div>
    );
};

export default Release;