import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FastApplication, MountedReference, sortedSupportedApplications } from '../FastConstants';
import { graphql, useStaticQuery } from 'gatsby';
import { getIdToken, isPermittedForUserRoles } from '../../../services/auth';
import equal from 'fast-deep-equal';
import { ReleaseMetadataForm } from './ReleaseMetadataForm';
import { format } from 'date-fns';
import { ReleaseMetadata } from '../../../../../infrastructure/src/maiadeploy/model/releaseMetadata';
import { InstanceRelease } from '../../../../../infrastructure/src/maiadeploy/model/instanceRelease';
import { Release } from '../../../../../infrastructure/src/maiadeploy/model/release';

const versionFromArtifactString = (artifact: string) : string => {
    const regex = /releases\/.*\/([0-9]+\.[0-9]+\.[0-9]+)\/.*/;
    if (!artifact) {
        return 'missing';
    }
    const match = artifact.match(regex);
    if (!match) {
        return 'error';
    }
    return match[1];
};

const ReleaseTabPaneContents = () => {
    const mounted : MountedReference = {mounted: true};

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
        }
      }
    }
  `);

    const [releases, setReleases] = useState<{[key: string] : ReleaseMetadata}>({});

    const [applications] = useState<FastApplication[]>(sortedSupportedApplications);
    const [selectedApplication, setSelectedApplication] = useState<string>('');

    const [isDownloadingRelease, setIsDownloadingRelease] = useState(false);
    const [isDownloadingReleaseMetadataList, setIsDownloadingReleaseMetadataList] = useState(true);
    const [selectedReleaseMetadata, setSelectedReleaseMetadata] = useState<ReleaseMetadata>();

    const [isReleaseMetadataDirty, setIsReleaseMetadataDirty] = useState(false);

    const [isReleaseLocked, setIsReleaseLocked] = useState(false);

    const [newReleaseMetadata, setNewReleaseMetadata] = useState<ReleaseMetadata>();
    const [showCreateNewReleaseForm, setShowCreateNewReleaseForm] = useState<boolean>(false);
    const [showDeleteReleaseDialog, setShowDeleteReleaseDialog] = useState<boolean>(false);

    const [selectedInstanceRelease, setSelectedInstanceRelease] = useState<InstanceRelease>();

    const toggleShowCreateNewReleaseForm= () => setShowCreateNewReleaseForm(!showCreateNewReleaseForm);
    const toggleShowDeleteReleaseDialog= () => setShowDeleteReleaseDialog(!showDeleteReleaseDialog);

    const updateOnSelectReleaseMetadata = async (releaseId: string | undefined, metadata : ReleaseMetadata | undefined = undefined) => {
        if (mounted.mounted) {
            setSelectedReleaseMetadata(releaseId && !metadata? releases[releaseId] : metadata);
            setIsReleaseMetadataDirty(false);

            setSelectedInstanceRelease(undefined);
            setIsReleaseLocked(false);
            if (releaseId) {
                setIsDownloadingRelease(true);
                return fetch('/api/maiadeploy/releases/' + releaseId, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken(),
                  },
                })
                    .then(response => response.json())
                    .then((release: Release) => {
                        if (mounted.mounted) {
                            setSelectedInstanceRelease(release.instanceReleases && release.instanceReleases[0] ? release.instanceReleases[0] : undefined);
                            setIsReleaseLocked(release.metadata?.status == ReleaseMetadata.StatusEnum.Draft || release.metadata?.status == ReleaseMetadata.StatusEnum.DraftFailedLocked);
                            setIsDownloadingRelease(false);
                        }
                    }).catch((err) => {
                        console.log("Error downloading release details: " + err)
                    });
            }
        }
    };
    const onSelectReleaseMetadata = (event: any) => {
        updateOnSelectReleaseMetadata(event.target.value);
    };


    const onSelectApplication = (event: any) => {
    };

    const addBinary = () => {

    };

    const addConfig = () => {

    };

    const clearSelected = () => {

    };

    const onReleaseTypeChange = (event: any) => {
        if (selectedReleaseMetadata) {
            const release = Object.assign({}, selectedReleaseMetadata);
            switch (event.target.value) {
                case 'MAIA_FULL':
                        release.releaseType = ReleaseMetadata.ReleaseTypeEnum.MaiaFull;
                    break;
                case 'MAIA_PARTIAL':
                        release.releaseType = ReleaseMetadata.ReleaseTypeEnum.MaiaPartial;
                    break;
                case 'OTHER':
                        release.releaseType = ReleaseMetadata.ReleaseTypeEnum.Other;
                    break;
            }
            selectedReleaseMetadata.id && setIsReleaseMetadataDirty(!equal(releases[selectedReleaseMetadata.id], release));
            setSelectedReleaseMetadata(release);
        }

    };

    const onNewReleaseNameEditChange = (event : any) => {
        if (newReleaseMetadata) {
            const release = Object.assign({}, newReleaseMetadata);
            release.name = event.target.value.trim();
            setNewReleaseMetadata(release);
        }

    };

    const onNewReleaseTypeChange = (event: any) => {
        if (newReleaseMetadata) {
            const release = Object.assign({}, newReleaseMetadata);
            switch (event.target.value) {
                case 'MAIA_FULL':
                        release.releaseType = ReleaseMetadata.ReleaseTypeEnum.MaiaFull;
                    break;
                case 'MAIA_PARTIAL':
                        release.releaseType = ReleaseMetadata.ReleaseTypeEnum.MaiaPartial;
                    break;
                case 'OTHER':
                        release.releaseType = ReleaseMetadata.ReleaseTypeEnum.Other;
                    break;
            }
            setNewReleaseMetadata(release);
        }
    };

    const onNewReleasePrimaryDataMigrationChange = (event: any) => {
        if (newReleaseMetadata) {
            const release = Object.assign({}, newReleaseMetadata);
            release.performDataMigration = event.target.checked;
            setNewReleaseMetadata(release);
        }
    };

    const onNewReleaseSecondaryDataMigrationChange = (event: any) => {
        if (newReleaseMetadata) {
            const release = Object.assign({}, newReleaseMetadata);
            release.performSecondaryDataMigration = event.target.checked;
            setNewReleaseMetadata(release);
        }
    };

    const onPrimaryDataMigrationChange = (event: any) => {
        if (selectedReleaseMetadata) {
            const release = Object.assign({}, selectedReleaseMetadata);
            release.performDataMigration = event.target.checked;
            selectedReleaseMetadata.id && setIsReleaseMetadataDirty(!equal(releases[selectedReleaseMetadata.id], release));
            setSelectedReleaseMetadata(release);
        }
    };

    const onSecondaryDataMigrationChange = (event: any) => {
        if (selectedReleaseMetadata) {
            const release = Object.assign({}, selectedReleaseMetadata);
            release.performSecondaryDataMigration = event.target.checked;
            selectedReleaseMetadata.id && setIsReleaseMetadataDirty(!equal(releases[selectedReleaseMetadata.id], release));
            setSelectedReleaseMetadata(release);
        }
    };

    const doCreateNewRelease = async () => {
        const metadata = Object.assign({}, newReleaseMetadata);
        const formattedDate = format(new Date(), "yyyyMMdd'T'HHmm");
        metadata.name = `${formattedDate}-${metadata.name}`;

        if (newReleaseMetadata) {
            fetch('/api/maiadeploy/releases', {
                method: 'POST',
                body: JSON.stringify(metadata),
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': await getIdToken(),
                },
            })
            .then(response => response.json())
            .then((release: Release) => {
                if (mounted.mounted && release && release.metadata && release.metadata.id) {
                    const allReleases = Object.assign({}, releases);
                    allReleases[release.metadata.id] = release.metadata;
                    setReleases(allReleases);
                    setSelectedReleaseMetadata(release.metadata);
                }
                updateOnSelectReleaseMetadata(release.metadata?.id, release.metadata);
            })
            .catch((err) => {
                console.log("Error creating new release: " + err);
            });
        }
        setShowCreateNewReleaseForm(false);
    };

    const downloadReleases = async (mounted : MountedReference) => {
        setIsDownloadingReleaseMetadataList(true);
        return fetch('/api/maiadeploy/releases', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: ReleaseMetadata[]) => {
                if (mounted.mounted) {
                    setReleases(resultData.reduce((map: {[key: string] : ReleaseMetadata}, release) => {
                        if (release.id) {
                            map[release.id] = release;
                        }
                        return map;}, {}));
                    setIsDownloadingReleaseMetadataList(false);
                }
            }).catch((err) => {
            console.log("Error downloading releases " + err);
        });
    };

    const onCloneRelease = () => {
        console.log('clone release');
    };

    const onCreateNewRelease = () => {
        const newRelease : ReleaseMetadata = {
            name: '',
            performDataMigration: false,
            performSecondaryDataMigration: false,
            releaseType: ReleaseMetadata.ReleaseTypeEnum.MaiaFull,
            version: '1',
        };
        setNewReleaseMetadata(newRelease);
        setShowCreateNewReleaseForm(true);
    };

    const onUpdateReleaseMetadata = () => {
        console.log('update release');

    };

    const doDeleteRelease = async () => {
        if (selectedReleaseMetadata && selectedReleaseMetadata.id) {
            const deletedId = selectedReleaseMetadata.id;
            fetch('/api/maiadeploy/releases/' + deletedId, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': await getIdToken(),
                },
            })
                .then(() => {
                    if (mounted.mounted) {
                        const allReleases = Object.assign({}, releases);
                        delete allReleases[deletedId];
                        setReleases(allReleases);
                        setSelectedReleaseMetadata(undefined);
                        updateOnSelectReleaseMetadata(undefined);
                    }
                }).catch((err) => {
                console.log("Error downloading releases " + err);
            });
        }
        setShowDeleteReleaseDialog(false);
    };

    const onDeleteRelease = () => {
        toggleShowDeleteReleaseDialog();
    };


    const removeApplication = (appName: string) => {
      console.log("Remove app from definition: " + appName);
    };

    useEffect(() => {
        downloadReleases(mounted);

        return () => {mounted.mounted = false};
    }, []);

    return (
        <Row>
            {isPermittedForUserRoles(data.site.siteMetadata.client, ['admin'], true) ?
                <Col sm="12">
                    <Form>
                        <FormGroup tag="fieldset">
                            <legend className={'text-muted'}>Definition</legend>
                            <Row form>
                                <Col sm={12} md={6}>

                                    <FormGroup>
                                        <Label for="deployments">Existing releases</Label>
                                        <Input type="select" name="releases" id="releases" value={selectedReleaseMetadata?.id}
                                               onChange={onSelectReleaseMetadata} size={15}>
                                                <option key={''} value={''} hidden={true}/>
                                            {
                                                isDownloadingReleaseMetadataList ? <option className="lds-dual-ring"/> :
                                                Object.values(releases).map(val =>
                                                    <option key={val.id} value={val.id}>{val.name}</option>,
                                                )
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col sm={12} md={6}>
                                    <ReleaseMetadataForm selectedReleaseMetadata={selectedReleaseMetadata}
                                                         onReleaseTypeChange={onReleaseTypeChange}
                                                         onPrimaryDataMigrationChange={onPrimaryDataMigrationChange}
                                                         onSecondaryDataMigrationChange={onSecondaryDataMigrationChange}
                                                         allowNameEdit={false}
                                                         idPrefix={'selectedRelease-'}

                                    />
                                    <hr/>
                                    <Label>Status:</Label> {selectedReleaseMetadata?.status}{isReleaseMetadataDirty && ', UNSAVED'}

                                </Col>
                            </Row>
                            <Row form>
                                <Col sm={6} md={{ size: 2, offset: 2 }}>
                                    <Button outline block onClick={onCloneRelease}>Clone</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Button outline block onClick={onCreateNewRelease}>Create new</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Button outline block disabled={!isReleaseMetadataDirty} onClick={onUpdateReleaseMetadata}>Update</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Button outline block color={'danger'} onClick={onDeleteRelease} disabled={!isReleaseLocked}>Delete</Button>
                                </Col>

                            </Row>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend className={'text-muted'}>Applications</legend>
                            <Row form>
                                <Col sm={6} md={{ size: 2, offset: 2 }}>
                                    <Button outline block disabled>All latest</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Button outline block disabled>Minimal</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Button outline block disabled>Save</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Button outline block color={'danger'} onClick={clearSelected}>Clear</Button>
                                </Col>

                            </Row>

                            <Row form>
                                <Col sm={4}>
                                    <FormGroup className={'applicationListContainer'}>
                                        <Label for="applications">Available applications</Label>
                                        <Input type="select" name="applications" id="applications" size={13}
                                               value={selectedApplication}
                                               onChange={onSelectApplication}>
                                            <option value="" hidden></option>
                                            {
                                                applications.map(app =>
                                                    <option key={app.group} value={app.id}>{app.name}</option>,
                                                )
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} className={'artifactVersions'}>
                                    <FormGroup className={'artifactVersionContainer'}>
                                        <Label for="binaries">Binary</Label>
                                        <InputGroup>
                                            <Input type="select" name="binaries" id="binaries" size={5}
                                                // value={selectedBinaryVersion?.version}
                                                // onChange={onSelectBinaryVersion}
                                            >
                                                <option value="" hidden></option>

                                                {/*{*/}
                                                {/*    isQueryingBinaryVersions ? <option className="lds-dual-ring"></option> :*/}
                                                {/*        Array.from(applicationBinaryVersions.keys()).map(version =>*/}
                                                {/*            <option key={version} value={version}>{version}</option>,*/}
                                                {/*        )*/}
                                                {/*}*/}
                                            </Input>
                                            <InputGroupAddon addonType="append">
                                                <Button color="secondary" outline onClick={addBinary}><FontAwesomeIcon
                                                    icon={faAngleDoubleRight}/></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className={'artifactVersionContainer'}>
                                        <Label for="config">Configuration</Label>
                                        <InputGroup>

                                            <Input type="select" name="config" id="config" size={5}
                                                // value={selectedConfigVersion?.version}
                                                // onChange={onSelectConfigVersion}
                                            >
                                                <option value="" hidden></option>
                                                {/*{*/}
                                                {/*    isQueryingConfigVersions ? <option className="lds-dual-ring"></option> :*/}
                                                {/*        Array.from(applicationConfigVersions.keys()).map(version =>*/}
                                                {/*            <option key={version} value={version}>{version}</option>,*/}
                                                {/*        )*/}
                                                {/*}*/}
                                            </Input>
                                            <InputGroupAddon addonType="append">
                                                <Button color="secondary" outline onClick={addConfig}><FontAwesomeIcon
                                                    icon={faAngleDoubleRight}/></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} className={'targeted-deployment-column'}>
                                    <Label>Release definition</Label>
                                    <div className={'targeted-deployment-list'}>
                                        <ListGroup>
                                            {
                                                selectedInstanceRelease &&
                                                Array.from(selectedInstanceRelease.applications.values()).map((app) =>
                                                    <ListGroupItem key={app.name}
                                                                   className={!app.binary || !app.config ? 'invalid' : ''}>
                                                        <div className={'text-muted'}>
                                                            <strong>{app.name}</strong>
                                                            <FontAwesomeIcon
                                                                className={'application-action-icon fa-semitransparent'}
                                                                icon={faTrashAlt} size="sm"
                                                                onClick={() => removeApplication(app.name)}/>
                                                        </div>
                                                        <div>
                                                            <span
                                                                className={'text-muted'}>binary:</span> <span
                                                            className={!app.binary ? 'missing' : ''}>{versionFromArtifactString(app.binary)}</span>
                                                            <span className={'config-version'}><span
                                                                className={'text-muted'}>config:</span> <span
                                                                className={!app.config ? 'missing' : ''}>{versionFromArtifactString(app.config['dev'])}, {versionFromArtifactString(app.config['uat'])}, {versionFromArtifactString(app.config['prod'])}</span></span>
                                                        </div>
                                                    </ListGroupItem>,
                                                )
                                            }
                                        </ListGroup>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend className={'text-muted'}>Sign-offs</legend>
                            <Row form>
                                <p>Details of sign-offs will go here. Users may sign-off on the release based on their
                                    role,
                                    which will unlock the ability to release to environments.</p>
                            </Row>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend className={'text-muted'}>Release</legend>
                            <p>When you are finished editing the release definition for all environments, lock the
                                release which will prepare the release. No further changes will be possible for that
                                release in any environment. When the release is ready then you can choose to execute the
                                release to a chosen environment.</p>
                            <Row form className={'release-actions'}>
                                <Col sm={6} md={{ size: 2, offset: 5 }}>
                                    <Button outline block color={'danger'} disabled id={'lock'}>Lock</Button>
                                </Col>
                            </Row>
                            <Row form className={'release-actions'}>
                                <Col sm={6} md={{ size: 2, offset: 3 }}>
                                    <Label for="releaseDev">status</Label>
                                    <Button outline block color={'danger'} disabled id={'releaseDev'}>Release
                                        Dev</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Label for="releaseDev">status</Label>
                                    <Button outline block color={'danger'} disabled id={'releaseUat'}>Release
                                        UAT</Button>
                                </Col>
                                <Col sm={6} md={{ size: 2 }}>
                                    <Label for="releaseDev">status</Label>
                                    <Button outline block color={'danger'} disabled id={'releaseProd'}>Release
                                        Prod</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Col> : <Col sm="12"><p>The release functionality is currently in preview only and not available for you to access.</p></Col>
            }

            <Modal isOpen={showCreateNewReleaseForm}>
                <ModalHeader toggle={toggleShowCreateNewReleaseForm}>Create new empty release?</ModalHeader>
                <ModalBody>
                    <ReleaseMetadataForm selectedReleaseMetadata={newReleaseMetadata}
                                         onReleaseTypeChange={onNewReleaseTypeChange}
                                         onPrimaryDataMigrationChange={onNewReleasePrimaryDataMigrationChange}
                                         onSecondaryDataMigrationChange={onNewReleaseSecondaryDataMigrationChange}
                                         allowNameEdit={true}
                                         onNameEditChange={onNewReleaseNameEditChange}
                                         idPrefix={'newRelease-'}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={doCreateNewRelease}>Create</Button>{' '}
                    <Button color="secondary" onClick={toggleShowCreateNewReleaseForm}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showDeleteReleaseDialog}>
                <ModalHeader toggle={toggleShowDeleteReleaseDialog}>Delete release?</ModalHeader>
                <ModalBody>
                    Do you wish to delete the release <strong>{selectedReleaseMetadata?.name}</strong>? This cannot be undone.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={doDeleteRelease}>Delete</Button>{' '}
                    <Button color="secondary" onClick={toggleShowDeleteReleaseDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Row>
    );
};

export default ReleaseTabPaneContents;