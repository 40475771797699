import DashboardPage from '../DashboardPage';
import React from 'react';
import Status from './status/Status';
import Release from './release/Release';
import './control-plane.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { isPermittedForUserRoles } from '../../services/auth';
import { SideMenuItem } from '../Sidebar';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    action?: any;
    location?: any;
}

const renderControlAction = (action: string, state: any) => {
    switch (action) {
        case 'status':
            return (<Status/>);
        case 'release':
            return (<Release/>);
        default:
            return null;
    }
};

export const ControlPlane = ({ action, location }: Props) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
        }
      }
    }
  `);

    // for now, status page is in preview
    const sideMenuItems: SideMenuItem[] = isPermittedForUserRoles(data.site.siteMetadata.client, ['admin'], true) ? [
        { title: 'Status', link: '/app/control/status' },
    ] : [];

    sideMenuItems.push(
        { title: 'Releases', link: '/app/control/release' },
    );

    return (
        <DashboardPage seoTitle={'Control Plane'} sideMenuItems={sideMenuItems}>
            {
                renderControlAction(action, location.state)
            }
        </DashboardPage>
    );
};