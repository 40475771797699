import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
import { GuiArtifact } from '../../downloads/Downloads';
import QaTabPaneSection from './QaTabPaneSection';
import { MountedReference } from '../FastConstants';
import { getIdToken } from '../../../services/auth';

const capitalize = (s: any) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const QaTabPaneContents = () => {
    const numberOfVersionsToDisplay = 20;
    const mounted : MountedReference = {mounted: true};

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
        }
      }
    }
  `);

    const [devVersions, setDevVersions] = useState<GuiArtifact[]>([]);
    const [uatVersions, setUatVersions] = useState<GuiArtifact[]>([]);
    const [prodVersions, setProdVersions] = useState<GuiArtifact[]>([]);
    const [installerVersions, setInstallerVersions] = useState<GuiArtifact[]>([]);

    const [qaDevVersions, setQaDevVersions] = useState<GuiArtifact[]>([]);
    const [qaUatVersions, setQaUatVersions] = useState<GuiArtifact[]>([]);
    const [qaProdVersions, setQaProdVersions] = useState<GuiArtifact[]>([]);
    const [qaInstallerVersions, setQaInstallerVersions] = useState<GuiArtifact[]>([]);

    const [isDownloadingDev, setIsDownloadingDev] = useState(true);
    const [isDownloadingUat, setIsDownloadingUat] = useState(true);
    const [isDownloadingProd, setIsDownloadingProd] = useState(true);
    const [isDownloadingInstaller, setIsDownloadingInstaller] = useState(true);

    const [isDownloadingDevQa, setIsDownloadingDevQa] = useState(false);
    const [isDownloadingUatQa, setIsDownloadingUatQa] = useState(false);
    const [isDownloadingProdQa, setIsDownloadingProdQa] = useState(true);
    const [isDownloadingInstallerQa, setIsDownloadingInstallerQa] = useState(true);

    const [isTaggingProd, setIsTaggingProd] = useState(false);
    const [isTaggingUat, setIsTaggingUat] = useState(false);
    const [isTaggingDev, setIsTaggingDev] = useState(false);
    const [isTaggingInstaller, setIsTaggingInstaller] = useState(false);

    const setTaggingByEnv = (env: string, state: boolean) => {
        switch (env) {
            case 'prod':
                setIsTaggingProd(state);
                break;

            case 'uat':
                setIsTaggingUat(state);
                break;

            case 'dev':
                setIsTaggingDev(state);
                break;

            case 'installer':
                setIsTaggingInstaller(state);
        }
    };

    const callMarkVersion = async (env: string, version: string, deployment: string) => {

        setTaggingByEnv(env, true);

        fetch('/api/maiadeploy/releases/artifacts/gui/qa?version=' + version + '&env=' + env + '&deployment=' + deployment, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': await getIdToken(),
            },
        })
            .then(response => {
                if (response.status != 204) {
                    console.log('Error tagging binary: ' + response.status);
                } else {
                    switch (env) {
                        case 'prod':
                            return downloadProdQa(mounted);

                        case 'uat':
                            return downloadUatQa(mounted);

                        case 'dev':
                            return downloadDevQa(mounted);

                        case '':
                            return downloadInstallerQa(mounted);
                    }
                }
            }).finally(() => {
            setTaggingByEnv(env, false);
        });
    };

    const markProdVersion = (version: string, deployment: string) => {
        callMarkVersion('prod', version, deployment);
    };

    const markUatVersion = (version: string, deployment: string) => {
        callMarkVersion('uat', version, deployment);
    };

    const markDevVersion = (version: string, deployment: string) => {
        callMarkVersion('dev', version, deployment);
    };

    const markInstallerVersion = (version: string) => {
        callMarkVersion('', version, '');
    }

    const callUnmarkVersion = async (env: string, version: string, deployment: string) => {
        setTaggingByEnv(env, true);

        fetch('/api/maiadeploy/releases/artifacts/gui/qa?version=' + version + '&env=' + env + '&deployment=' + deployment, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': await getIdToken(),
            },
        })
            .then(response => {
                if (response.status != 204) {
                    console.log('Error untagging binary: ' + response.status);
                } else {
                    switch (env) {
                        case 'prod':
                            return downloadProdQa(mounted);

                        case 'uat':
                            return downloadUatQa(mounted);

                        case 'dev':
                            return downloadDevQa(mounted);

                        case '':
                            return downloadInstallerQa(mounted)
                    }
                }
            }).finally(() => {
            setTaggingByEnv(env, false);
        });
    };

    const unmarkProdVersion = (version: string, deployment: string) => {
        callUnmarkVersion('prod', version, deployment);
    };

    const unmarkUatVersion = (version: string, deployment: string) => {
        callUnmarkVersion('uat', version, deployment);
    };

    const unmarkDevVersion = (version: string, deployment: string) => {
        callUnmarkVersion('dev', version, deployment);
    };

    const unmarkInstallerVersion = (version: string) => {
        callUnmarkVersion('', version, '');
    }

    const downloadProd = async (mounted : MountedReference) => {
        setIsDownloadingProd(true);
        return fetch('/api/portal/download/gui?env=prod&qa=false', {
          headers: {
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setProdVersions(resultData);
                    setIsDownloadingProd(false);
                }
            });

    };

    const downloadProdQa = async (mounted : MountedReference) => {
        setIsDownloadingProdQa(true);
        return fetch('/api/portal/download/gui?env=prod&qa=true', {
          headers: {
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setQaProdVersions(resultData);
                    setIsDownloadingProdQa(false);
                }
            });
    };

    const downloadUat = async (mounted : MountedReference) => {
        setIsDownloadingUat(true);
        return fetch('/api/portal/download/gui?env=uat&qa=false', {
          headers: {
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setUatVersions(resultData);
                    setIsDownloadingUat(false);
                }
            });
    };

    const downloadUatQa = async (mounted : MountedReference) => {
        setIsDownloadingUatQa(true);
        return fetch('/api/portal/download/gui?env=uat&qa=true', {
          headers: {
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setQaUatVersions(resultData);
                    setIsDownloadingUatQa(false);
                }
            });
    };

    const downloadDev = async (mounted : MountedReference) => {
        setIsDownloadingDev(true);
        return fetch('/api/portal/download/gui?env=dev&qa=false', {
          headers: {
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setDevVersions(resultData);
                    setIsDownloadingDev(false);
                }
            });
    };

    const downloadDevQa = async (mounted : MountedReference) => {
        setIsDownloadingDevQa(true);
        return fetch('/api/portal/download/gui?env=dev&qa=true', {
          headers: {
            'Authorization': await getIdToken(),
          },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setQaDevVersions(resultData);
                    setIsDownloadingDevQa(false);
                }
            });
    };

    const downloadInstaller = async (mounted : MountedReference) => {
        setIsDownloadingInstaller(true);
        return fetch('/api/portal/download/installer?qa=false', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getIdToken(),
            },
        })
            .then(response => {
                return response
            })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setInstallerVersions(resultData);
                    setIsDownloadingInstaller(false);
                }
            });
    }

    const downloadInstallerQa = async (mounted : MountedReference) => {
        setIsDownloadingInstaller(true);
        return fetch('/api/portal/download/installer?qa=true', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getIdToken(),
            },
        })
            .then(response => response.json())
            .then((resultData: GuiArtifact[]) => {
                if (mounted.mounted) {
                    resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                    setQaInstallerVersions(resultData);
                    setIsDownloadingInstallerQa(false);
                }
            });
    }

    useEffect(() => {

        downloadProd(mounted);
        downloadProdQa(mounted);

        downloadUat(mounted);
        downloadUatQa(mounted);

        downloadDev(mounted);
        downloadDevQa(mounted);

        downloadInstaller(mounted);
        downloadInstallerQa(mounted);

        return () => {mounted.mounted = false};
    }, []);

    return (
        <Row>
            <Col sm="12">
                <p>Mark a version of the <strong>ui_maia</strong> binary as having been tested for an environment and it
                    will appear in the <strong>{capitalize(data.site.siteMetadata.client)}</strong> user portal for
                    download.</p>
                <Form>
                    <QaTabPaneSection versions={installerVersions} qaVersions={qaInstallerVersions}
                                      isDownloading={isDownloadingInstaller} isDownloadingQa={isDownloadingInstallerQa}
                                      title={'Installer'} env={'installer'} markVersion={markInstallerVersion}
                                      unmarkVersion={unmarkInstallerVersion} isTagging={isTaggingInstaller} />

                    <QaTabPaneSection versions={prodVersions} qaVersions={qaProdVersions}
                                      isDownloading={isDownloadingProd} isDownloadingQa={isDownloadingProdQa}
                                      title={'Prod'} env={'prod'} markVersion={markProdVersion}
                                      unmarkVersion={unmarkProdVersion} isTagging={isTaggingProd}/>
                    <QaTabPaneSection versions={uatVersions} qaVersions={qaUatVersions} isDownloading={isDownloadingUat}
                                      isDownloadingQa={isDownloadingUatQa} title={'UAT'} env={'uat'}
                                      markVersion={markUatVersion} unmarkVersion={unmarkUatVersion}
                                      isTagging={isTaggingUat}/>
                    <QaTabPaneSection versions={devVersions} qaVersions={qaDevVersions} isDownloading={isDownloadingDev}
                                      isDownloadingQa={isDownloadingDevQa} title={'Dev'} env={'dev'}
                                      markVersion={markDevVersion} unmarkVersion={unmarkDevVersion}
                                      isTagging={isTaggingDev}/>
                </Form>
            </Col>

        </Row>
    );
};

export default QaTabPaneContents;