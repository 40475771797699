export const targetEnvs: string[] = [
    'dev',
    'uat',
];

export const tzs = [
    'Europe/London',
    'Asia/Tokyo',
    'Australia/Sydney',
];

export interface FastApplication {
    name: string;
    id: string;
    group: string;
}

export interface MountedReference {
    mounted: boolean;
}

export const supportedApplications: Map<string, FastApplication> = new Map([
    ['AddressEngine',
        {
            name: 'Address',
            id: 'AddressEngine',
            group: 'com.fulcrum.address',
        },
    ],
    [
        'ConfigurationEngine',
        {
            name: 'Configuration',
            id: 'ConfigurationEngine',
            group: 'com.fulcrum.configuration',
        },
    ],
    [
        'EnvironmentEngine',
        {
            name: 'Environment',
            id: 'EnvironmentEngine',
            group: 'com.fulcrum.environment',
        },

    ],
    [
        'OMEngine',
        {
            name: 'Order Manager',
            id: 'OMEngine',
            group: 'com.fulcrum.om',
        },

    ],
    [
        'Persistence',
        {
            name: 'Persistence',
            id: 'Persistence',
            group: 'com.fulcrum.persistence',
        },

    ],
    [
        'InstrumentEngine', {
        name: 'Instrument',
        id: 'InstrumentEngine',
        group: 'com.fulcrum.instrument',
    },

    ],
    [
        'ComplianceEngine', {
        name: 'Compliance',
        id: 'ComplianceEngine',
        group: 'com.fulcrum.compliance',
    },
    ],
    [
        'CashEngine', {
        name: 'Cash',
        id: 'CashEngine',
        group: 'com.fulcrum.cash',
    },

    ],
    [
        'BenchmarkEngine', {
        name: 'Benchmark',
        id: 'BenchmarkEngine',
        group: 'com.fulcrum.benchmark',
    },
    ],
    [
        'EnrichmentEngine', {
        name: 'Enrichment',
        id: 'EnrichmentEngine',
        group: 'com.fulcrum.enrichment',
    },
    ],
    [
        'PositionEngine', {
        name: 'Position',
        id: 'PositionEngine',
        group: 'com.fulcrum.position',
    },
    ],
    [
        'MarketDataEngine', {
        name: 'Market Data',
        id: 'MarketDataEngine',
        group: 'com.fulcrum.market_data',
    },
    ],
    [
        'CashTradingEngine', {
        name: 'Cash Trading',
        id: 'CashTradingEngine',
        group: 'com.fulcrum.cash_trading',
    },
    ],
    [
        'RollsEngine', {
        name: 'Rolls',
        id: 'RollsEngine',
        group: 'com.fulcrum.rolls',
    },
    ],
    [
        'PnL2Engine', {
        name: 'PnL 2',
        id: 'PnL2Engine',
        group: 'com.fulcrum.pnl2',
    },
    ],
    [
        'MarkitWireEngine', {
        name: 'MarkitWire',
        id: 'MarkitWireEngine',
        group: 'com.fulcrum.markitwire',
    },
    ],
    [
        'OrderGeneratorEngine', {
        name: 'Order Generator',
        id: 'OrderGeneratorEngine',
        group: 'com.fulcrum.order_gen',
    },
    ],
    [
        'PostTrade2Engine', {
        name: 'Post-Trade 2',
        id: 'PostTrade2Engine',
        group: 'com.fulcrum.posttrade2',
    },
    ],
    [
        'TotalReturnEngine',
        {
            name: 'Total Return',
            id: 'TotalReturnEngine',
            group: 'com.fulcrum.total_returns',
        },
    ],
    [
        'RiskRealTimeEngine', {
        name: 'Risk Real Time',
        id: 'RiskRealTimeEngine',
        group: 'com.fulcrum.risk_realtime',
    },

    ],
    [
        'RiskMetrics2Engine', {
        name: 'Risk Metrics 2',
        id: 'RiskMetrics2Engine',
        group: 'com.fulcrum.riskmetrics2',
    },

    ],
    [
        'SchedulerEngine', {
        name: 'Scheduler',
        id: 'SchedulerEngine',
        group: 'com.fulcrum.reporting',
    },

    ],
    [
        'AUMEngine', {
        name: 'AUM',
        id: 'AUMEngine',
        group: 'com.fulcrum.aum',
    },

    ],
    [
        'ExposuresEngine', {
        name: 'Exposures',
        id: 'ExposuresEngine',
        group: 'com.fulcrum.exposures',
    },
    ],
    [
        'AccrualsEngine', {
        name: 'Accruals',
        id: 'AccrualsEngine',
        group: 'com.fulcrum.accruals',
    },

    ],
    [
        'ReconEngine', {
        name: 'Reconciliation',
        id: 'ReconEngine',
        group: 'com.fulcrum.recon',
    },
    ],
    [
        'OmgeoEngine', {
        name: 'Omgeo',
        id: 'OmgeoEngine',
        group: 'com.fulcrum.omgeo',
    },
    ],
    [
        'PortfolioManagerEngine', {
        name: 'Portfolio Manager',
        id: 'PortfolioManagerEngine',
        group: 'io.maiatech.pm',
    },
    ],
    [
        'tls_proxy', {
        name: 'TLS Proxy',
        id: 'tls_proxy',
        group: 'io.maiatech.tls_proxy',
    },
    ],
]);


export const sortedSupportedApplications: FastApplication[] = Array.from(supportedApplications.values())
    .sort((app1, app2) => {
        const name1 = app1.name.toLowerCase();
        const name2 = app2.name.toLowerCase();
        if (name1 < name2) {
            return -1;
        }
        if (name1 > name2) {
            return 1;
        }

        // names must be equal
        return 0;
    });


