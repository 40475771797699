import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { getIdToken } from '../../../../services/auth';
import { InfraMetadata } from '../../../../../../infrastructure/src/maiadeploy/model/infraMetadata';


interface Props {
    environment: string;
    tenant: string;
}

const EnginesEnvironmentPanel = ({ environment, tenant }: Props) => {

    const deploymentsEnv = 'deployments' + environment;
    const [isQueryingDeployments, setIsQueryingDeployments] = useState<boolean>(false);
    const [availableDeployments, setAvailableDeployments] = useState<string[]>([]);
    const [selectedDeployment, setSelectedDeployment] = useState<string>('');
    const [selectedEngineId, setSelectedEngineId] = useState<string>('');
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [engineOperation, setSelectedEngineOperation] = useState<'start' | 'stop' | ''>('');

    const onSelectDeployment = (e: any) => {
        setSelectedDeployment(e.target.value);
    };

    const toggleConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal);
    };

    const confirmOperation = (operation: 'start' | 'stop') => {
        setSelectedEngineOperation(operation);
        setShowConfirmationModal(true);
    };

    const performEngineAction = async () => {
        console.log(`${engineOperation} ${selectedEngineId} in ${selectedDeployment} ${environment}`);
        fetch(`/api/maiadeploy/applications/${environment}/${selectedDeployment}/${selectedEngineId}/${engineOperation}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getIdToken(),
            },
        })
        .catch((error) => {
            console.log('error: ' + error);
        })
        .finally(() => {
            setSelectedEngineId('');
            setSelectedEngineOperation('');
            setShowConfirmationModal(false);
        });
    };

    useEffect(() => {
        let mount = true;
        setIsQueryingDeployments(true);
        // hard code in linux os as we are creating infra in AWS
        async function fetchDeployments() {
          await fetch('/api/maiadeploy/maia/infra/' + environment, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': await getIdToken(),
            },
          })
            .then(response => response.json())
            .then((json: InfraMetadata[]) => {
              if (mount) {
                setAvailableDeployments(json.map(sys => sys.name));
                setIsQueryingDeployments(false);
              }
            })
            .catch((error) => {
              if (mount) {
                console.log('error: ' + error);
                setIsQueryingDeployments(false);
              }
            });
          return () => {
            mount = false;
          };
        }
        fetchDeployments();
    }, []);

    return (
        <Col sm="12">
            <Form>
                <FormGroup tag="fieldset">
                    <legend className={'text-muted'}>{environment.toUpperCase()}</legend>
                    <Row form>
                        <Col sm={12} md={6}>

                            <FormGroup>
                                <Label for={deploymentsEnv}>Available {environment.toUpperCase()} deployments</Label>
                                <Input type="select" name={deploymentsEnv} id={deploymentsEnv} size={10}
                                       value={selectedDeployment}
                                       onChange={onSelectDeployment}>
                                    {
                                        isQueryingDeployments ? <option className="lds-dual-ring"></option> :

                                            Array.of(<option key="" value="" hidden>None</option>).concat(
                                                Array.from(availableDeployments.map(deployment =>
                                                    <option key={deployment}
                                                            value={deployment}>{deployment}</option>,
                                                )),
                                            )
                                    }

                                </Input>
                            </FormGroup>
                        </Col>

                        <Col sm={12} md={6}>
                            <Row>
                                <Col sm={{ size: 10, offset: 1 }} className={'diffFileRow'}>
                                    <Label for="engineId">Enter the engine ID:</Label>
                                    <Input 
                                        type="text"
                                        name="engineId"
                                        id="engineId"
                                        value={selectedEngineId}
                                        disabled={selectedDeployment === ''}
                                        onChange={e => setSelectedEngineId(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: 10, offset: 1 }}>
                                    <Button
                                        outline
                                        disabled={selectedDeployment === '' || selectedEngineId === ''}
                                        onClick={() => confirmOperation('stop')}
                                        color={'info'}>
                                            Stop Engine
                                    </Button>
                                
                                    <Button
                                        outline
                                        disabled={selectedDeployment === '' || selectedEngineId === ''}
                                        onClick={() => confirmOperation('start')}
                                        color={'info'}
                                        style={{'margin-left': '1%'}}>
                                            Start Engine
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </FormGroup>
            </Form>

            <Modal isOpen={showConfirmationModal} toggle={toggleConfirmationModal}>
                <ModalHeader toggle={toggleConfirmationModal}>
                    {engineOperation} {selectedEngineId} within <strong>{selectedDeployment}</strong>?
                </ModalHeader>
                <ModalBody>
                    Do you wish to {engineOperation} {selectedEngineId} <strong>{selectedDeployment}</strong> within
                    the <strong>{environment.toUpperCase()}</strong> environment?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={toggleConfirmationModal}>Cancel</Button>
                    <Button color={'danger'} onClick={() => performEngineAction()}>Proceed</Button>{' '}
                </ModalFooter>
            </Modal>

        </Col>
    );
};

export default EnginesEnvironmentPanel;